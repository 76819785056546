import React, {SetStateAction, useEffect} from 'react'
import ColorPicker from 'react-best-gradient-color-picker'
import {ChangeStyleFunction, DeleteCustomStyleByNameFunction} from "../../../service/editModalService";
import {PageNode} from "../../../types/PageNode";


interface SelectColorProps {
    editableNode: PageNode,
    changeStyleFunction: ChangeStyleFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    styleName: string,
    isMobileStyle: boolean,
    labelName: string,
    showBlock: string,
    setShowBLock: React.Dispatch<SetStateAction<string>>
}

const SelectColor: React.FC<SelectColorProps> = ({changeStyleFunction, editableNode, styleName, isMobileStyle, labelName, showBlock, setShowBLock, deleteStyleByName}) => {
    let nodeCustomStyle: CustomStyle | undefined;
    if (editableNode) {
        if (isMobileStyle) {
            nodeCustomStyle = editableNode.customStyleMobile
        } else {
            nodeCustomStyle = editableNode.customStyle
        }
    }

    let gradient
    if (nodeCustomStyle && nodeCustomStyle[styleName] !== undefined) {
        gradient = nodeCustomStyle[styleName]
    } else {
        gradient = ''
    }
    let style = null

    if (gradient !== labelName){
        style = {'background': gradient, top: '15px', right: '85px'}
    }
    const handleChangeColor = (color: string): void => {
        changeStyleFunction(styleName, color, isMobileStyle)
    }

    // const handleClickResetImg = () => {
    //     changeStyleFunction(styleName, '', isMobileStyle)
    // }

    useEffect(() => {
        let isMouseDownInside = false;

        const handleMouseDown = (event: any): void => {
            isMouseDownInside = !!event.target.closest('.color-picker-input');
        };

        const handleMouseUp = (event: any): void => {
            if (showBlock === styleName && !event.target.closest('.color-picker-input') && !isMouseDownInside) {
                setShowBLock('');
            }
            isMouseDownInside = false;
        };

        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [setShowBLock, showBlock, styleName]);

    return(
        <>
            <div className="form-floating mb-md-0 mb-3">
                <div className="input-group">
                    <div className="form-floating ">
                        <input className="form-control"
                               // disabled={nodeCustomStyle[disableState] === false}
                               id="selectColorInput" placeholder={labelName} type="text" value={gradient} readOnly
                               onClick={() => {
                                   setShowBLock(styleName)
                               }}/>
                        <label htmlFor="selectColorInput" className="form-label">{labelName}</label>
                    </div>
                    <button
                        className={gradient
                        // && nodeCustomStyle[disableState]!==false
                            ? "btn btn-trash btn-outline-secondary px-4" : "btn btn-outline-secondary px-4 disabled bg-light"}
                        type="button" onClick={() => {
                        deleteStyleByName(styleName, isMobileStyle)
                    }}><i className="bi bi-trash icon-size"></i></button>
                </div>
                <div className="position-absolute rounded-1 p-3 pe-none border" style={style}></div>
                {showBlock === styleName ? (


                    <div
                        className="position-absolute translate-middle-x start-50 bg-white p-4 color-picker-input border rounded shadow">
                        <div className="d-flex justify-content-between">
                            <div className="mb-3">
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    deleteStyleByName(styleName, isMobileStyle)
                                    setShowBLock('')
                                }}>Clear
                                </button>
                            </div>
                            <div>
                                <button type="button" className="btn btn-close pt-3"
                                        onClick={() => setShowBLock('')}></button>
                            </div>
                        </div>
                        <ColorPicker className={'tmp'} value={gradient} onChange={handleChangeColor}/>
                        <div className="text-end">
                            <button type="button" className="btn btn-primary w-100 mt-3" onClick={() => setShowBLock('')}>Select</button>
                        </div>
                    </div>
                ) : null
                }
            </div>
        </>
    )
}

export default SelectColor