import React, { useEffect } from 'react';
import {ButtonNode} from "../../../types/PageNode";
import {CustomStyleField} from "../../../types/CustomStyleField";
import OnClickActionWrapper from "../Wrapper/OnClickActionWrapper";
import { changeIsMobileViewDimension } from '../../../reducers/app';

interface ButtonBlockProperty {
    nodeToShow: ButtonNode,
    styleObject: any,
    handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    isMobileViewDimension: boolean,
    className: string,
    handleDoubleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const ButtonBlock: React.FC<ButtonBlockProperty> = ({
                                                           nodeToShow,
                                                           styleObject,
                                                           handleClick,
                                                           className,
                                                           handleDoubleClick,
                                                           isMobileViewDimension
                                                       }) => {
    const value = nodeToShow[CustomStyleField.Value] ?? 'Add your custom text'
    const alignObject = isMobileViewDimension ? nodeToShow.customStyleMobile : nodeToShow.customStyle
    return (<>
        <OnClickActionWrapper nodeToShow={nodeToShow}>
            <style>
            {`
                .hover-color-${nodeToShow.id}{
                  transition: color 0.3s ease;
                &:hover{
                  color: ${!isMobileViewDimension ? (nodeToShow.customStyle?.hoverColor || "") : (nodeToShow.customStyleMobile?.hoverColor || "")} !important;
                }
                &:hover *{
                  color: ${!isMobileViewDimension ? (nodeToShow.customStyle?.hoverColor || "") : (nodeToShow.customStyleMobile?.hoverColor || "")} !important;
                }
                }
            `}
      </style>
            <div
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            id={'blockContent' + nodeToShow.id}
            className={`${className} hover-color-${nodeToShow.id}`}
            style={{...styleObject, borderColor: (alignObject?.borderColor || "transparent")}}

                // target={(nodeToShow[CustomStyleField.Target] !== undefined) ? nodeToShow[CustomStyleField.Target] : undefined}
            dangerouslySetInnerHTML={{__html: value}}
            ></div>
        </OnClickActionWrapper>
    </>);
};

export default ButtonBlock;
