// const TMP => () => {}
import React from "react";
import {AlignmentInput} from "./AlignmentInput";
import {
    changeBackClassesByValue,
    ChangeNodeFunction, deleteBackClassesByName
} from "../../../../service/editModalService";
import {PageNode} from "../../../../types/PageNode";
import {AlignTypes} from "./AlignTypes";
import {CustomStyleField} from "../../../../types/CustomStyleField";

export interface InputProperty {
    isMobileEditMode: boolean,
    editableNode: PageNode,
    changeNodeFunction: ChangeNodeFunction
}

export const AlignmentContainer: React.FC<InputProperty> = ({
                                                                isMobileEditMode,
                                                                editableNode,
                                                                changeNodeFunction
                                                            }) => {
    if (editableNode.backClassesIndexed === undefined) {
        editableNode.backClassesIndexed = {
            horizontalAlignment: undefined,
            verticalAlignment: undefined
        };
    }
    const changeBackFunction = (name: string, value: string, isEditMobileVersion: boolean) => {
        let tmpNode = changeBackClassesByValue(name, value, editableNode, isEditMobileVersion)
        if (isEditMobileVersion) {
            if (tmpNode.backClassesIndexed && (tmpNode.backClassesMobile.horizontalAlignment || tmpNode.backClassesMobile.verticalAlignment)) {
                tmpNode = changeBackClassesByValue(CustomStyleField.DFlex, 'd-flex', tmpNode, isEditMobileVersion)
            }
        } else {
            if (tmpNode.backClassesMobile && (tmpNode.backClassesIndexed.horizontalAlignment || tmpNode.backClassesIndexed.verticalAlignment)) {
                tmpNode = changeBackClassesByValue(CustomStyleField.DFlex, 'd-flex', tmpNode, isEditMobileVersion)
            }
        }
        changeNodeFunction(tmpNode)
    }

    const deleteBackFunction = (name: string, isEditMobileVersion: boolean) => {
        let tmpNode = deleteBackClassesByName(name, editableNode, isEditMobileVersion)
        if (isEditMobileVersion) {
            if (!tmpNode.backClassesMobile || (!tmpNode.backClassesMobile.horizontalAlignment && !tmpNode.backClassesMobile.verticalAlignment)) {
                tmpNode = deleteBackClassesByName(CustomStyleField.DFlex, tmpNode, isEditMobileVersion)
            }
        } else {
            if (!tmpNode.backClassesIndexed || (!tmpNode.backClassesIndexed.horizontalAlignment && !tmpNode.backClassesIndexed.verticalAlignment)) {
                tmpNode = deleteBackClassesByName(CustomStyleField.DFlex, tmpNode, isEditMobileVersion)
            }
        }
        changeNodeFunction(tmpNode)
    }

    return <>
        <div className={'border border-1 rounded  border-border-gray'}>
            <div className="d-flex justify-content-evenly my-2 text-background-mobile" role="group"
                 aria-label="Basic radio toggle button group">
                <AlignmentInput id={'AlignmentInput1'} isMobileEditMode={isMobileEditMode} value={'justify-content-start'}
                                name={'horizontalAlignment'} type={AlignTypes.Class}
                                iconValue={'bi bi-align-start'} htmlFor={'horizontalAlignment-justify-content-start'}
                                editableNode={editableNode}
                                changeByValueFunction={changeBackFunction}
                                deleteFunction={deleteBackFunction}/>
                <AlignmentInput id={'AlignmentInput2'} isMobileEditMode={isMobileEditMode} value={'justify-content-center'}
                                name={'horizontalAlignment'} type={AlignTypes.Class}
                                iconValue={'bi bi-align-center'} htmlFor={'horizontalAlignment-justify-content-center'}
                                editableNode={editableNode}
                                changeByValueFunction={changeBackFunction}
                                deleteFunction={deleteBackFunction}/>
                <AlignmentInput id={'AlignmentInput3'} isMobileEditMode={isMobileEditMode} value={'justify-content-end'}
                                name={'horizontalAlignment'} iconValue={'bi bi-align-end'}
                                htmlFor={'horizontalAlignment-justify-content-end'} type={AlignTypes.Class}
                                editableNode={editableNode}
                                changeByValueFunction={changeBackFunction}
                                deleteFunction={deleteBackFunction}/>
                <div className="vr"></div>
                <AlignmentInput id={'AlignmentInput4'} isMobileEditMode={isMobileEditMode}
                                value={'align-items-start'} name={'verticalAlignment'}
                                iconValue={'bi bi-align-top'} htmlFor={'verticalAlignment-text-left'}
                                editableNode={editableNode} type={AlignTypes.Class}
                                changeByValueFunction={changeBackFunction}
                                deleteFunction={deleteBackFunction}/>
                <AlignmentInput id={'AlignmentInput5'} isMobileEditMode={isMobileEditMode}
                                value={'align-items-center'} name={'verticalAlignment'} type={AlignTypes.Class}
                                iconValue={'bi bi-align-middle'} htmlFor={'verticalAlignment-text-center'}
                                editableNode={editableNode}
                                changeByValueFunction={changeBackFunction}
                                deleteFunction={deleteBackFunction}/>
                <AlignmentInput id={'AlignmentInput5'} isMobileEditMode={isMobileEditMode}
                                value={'align-items-end'} name={'verticalAlignment'} type={AlignTypes.Class}
                                iconValue={'bi bi-align-bottom'} htmlFor={'verticalAlignment-text-right'}
                                editableNode={editableNode}
                                changeByValueFunction={changeBackFunction}
                                deleteFunction={deleteBackFunction}/>
            </div>
        </div>
    </>
}

