import React, {useCallback} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {addNodeAfterActiveNode, addNodeToActiveNode, findTopParent, getNewNode, getNodeWithNewIds} from "../../../service/NodeService";
import {changeActiveModalNode} from "../../../reducers/node-modal";
import {Modal} from "bootstrap";
import CommonCategoryTemplates from "./CommonCategoryTemplates";
import {RootState} from "../../../store";
import {RequestPageType} from "../../../types/RequestPageType";
import UserTemplates from "../Template/UserTemplate/UserTemplates";
import {NodeType, PageNode} from "../../../types/PageNode";
import DivRowNodeFactory from "../../../service/Node/Factory/DivRowNodeFactory";
import PageService from "../../../service/Page/PageService";
import {useUserTemplateListQuery} from "../Template/UserTemplate/UserTemplates.hooks";
import {changeNewNode} from "../../../reducers/app";
import {UserRole} from "../../../types/User";
import ElementAddTab from '../modals/element-add-tab';


const ContainerAddModal = () => {
    const activeNode = useSelector((state: RootState) => state.nodeModal.value)
    const dispatch = useDispatch();
    const modalRef = React.useRef(null);
    const isMobileViewDimension = useSelector((state: RootState) => state.appStorage.isMobileViewDimension);
    const type = useSelector((state: RootState) => state.appStorage.type);
    const nodes = useSelector((state: RootState) => state.nodes.present.value);

    const handleClickOutside = useCallback((e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains('modal')) {
            dispatch(changeActiveModalNode(null));
            Modal.getOrCreateInstance('#container-to-add-modal').hide();
        }
    }, [dispatch]);

    React.useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            modalElement.addEventListener('mousedown', handleClickOutside);

            return () => {
                modalElement.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [handleClickOutside]);
    const user = useSelector((state: RootState) => state.appStorage.user);
    const isAdmin = user ? user.roles.includes(UserRole.ROLE_ADMIN) : false;
    const addEmptyContainer = () => {
        let nodeToAdd = getNewNode(NodeType.DivContainer, activeNode, {
            "tagName": "div",
            "className": "container",
            "backClassesIndexed": {'gx-0': 'gx-0'},
            'breadCrumbTitle': 'Block',
        })
        nodeToAdd.nodes.push(DivRowNodeFactory.getNode(nodeToAdd))
        nodeToAdd = getNodeWithNewIds(nodeToAdd);
        if (activeNode && activeNode.parentId === '1') {
            addNodeAfterActiveNode(dispatch, activeNode, nodeToAdd, nodes)
        } else {
            addNodeToActiveNode(dispatch, activeNode, nodeToAdd);
        }
        dispatch(changeNewNode(nodeToAdd))
        dispatch(changeActiveModalNode(nodeToAdd.nodes[0].nodes[0]));
        Modal.getOrCreateInstance('#edit-block-modal').show();
    }
    const addEmptyFluidContainer = () => {
        let nodeToAdd = getNewNode(NodeType.DivContainer, activeNode, {
            "tagName": "div",
            "className": "container-fluid",
            "backClassesIndexed": {'gx-0': 'gx-0'},
            'breadCrumbTitle': 'container-fluid',
        })
        nodeToAdd.nodes.push(DivRowNodeFactory.getNode(nodeToAdd))
        nodeToAdd = getNodeWithNewIds(nodeToAdd);
        if (activeNode && activeNode.parentId === '1') {
            addNodeAfterActiveNode(dispatch, activeNode, nodeToAdd, nodes)
        } else {
            addNodeToActiveNode(dispatch, activeNode, nodeToAdd);
        }
        dispatch(changeNewNode(nodeToAdd))
        dispatch(changeActiveModalNode(nodeToAdd.nodes[0]));
        Modal.getOrCreateInstance('#edit-block-modal').show();
    }
    const { data: templatesData } = useUserTemplateListQuery();
    // let templateNodes = nodes[0] ? getAllNodes(nodes[0]).filter((pageNode: PageNode) => pageNode.adminSettings?.isElementAllowedToAdd && pageNode.type === NodeType.DivContainer) : []
    let uniqueTemplateNodes = PageService.getInstance().page?.parentTemplate?.templates
    uniqueTemplateNodes = uniqueTemplateNodes ?? []

    return <div className="modal modal-lg" id="container-to-add-modal" tabIndex={-1} ref={modalRef}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header border-0 text-dark-gray">
                    <h5 className="modal-title mb-0 fs-5 d-md-none">All Blocks</h5>
                    <h5 className="modal-title mb-0 fs-4 d-none d-md-block">All Blocks</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => dispatch(changeActiveModalNode(null))}></button>
                </div>
                <div className="modal-body">
                    <div className="mb-3">
                        <nav>
                            <ul className="nav flex-nowrap border-bottom border-1 mb-4 overflow-x-scroll hide-scrollbar d-flex  justify-content-between "
                                id="nav-tab" role="tablist">
                                <li className='nav-item w-100'>
                                    <button className="nav-link rounded-0 fw-bold w-100 active" id="nav-ready-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-ready" type="button" role="tab"
                                            aria-controls="nav-ready"
                                            aria-selected="true">
                                                <b className="fs-5 d-lg-inline d-none">Ready-made Blocks</b>
                                                <b className="fs-8 d-lg-none">Ready-made</b>
                                    </button>
                                </li>
                                <li className='nav-item w-100'>
                                    <button className="nav-link block-tab rounded-0 fw-bold w-100" id="nav-elements-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-elements" type="button" role="tab"
                                            aria-controls="nav-elements"
                                            aria-selected="false">
                                                <b className="fs-5 d-lg-inline d-none">Elements</b>
                                                <b className="fs-8 d-lg-none">Elements</b>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="nav-ready" role="tabpanel" aria-labelledby="nav-ready-tab">
                            <div className={'container px-0'}>
                                <div className="accordion accordion-flush border-0" id="accordionExample">
                                    <div className="accordion-item border-0">
                                        {
                                            uniqueTemplateNodes.length > 0 && <>
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button-add-modal accordion-button collapsed text-capitalize fw-bold "
                                                        type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseTemplate" aria-expanded="false"
                                                        aria-controls="collapseTemplate">
                                                        Template Blocks
                                                    </button>
                                                </h2>
                                                <div id="collapseTemplate" className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample">
                                                    <div className="accordion-body pt-0">
                                                        <small className={'text-secondary'}>Choose ready made block</small>
                                                        <div className={'mt-2'}>


                                                            <div className={'row'}>
                                                                {uniqueTemplateNodes.map((template) => {
                                                                    let pageNode: PageNode = JSON.parse(template.content) as PageNode
                                                                    return <div className='col-12 col-md-4 '>
                                                                        <div
                                                                            onClick={() => {
                                                                                let pageNodeWithNewID = getNodeWithNewIds(pageNode);
                                                                                pageNodeWithNewID.adminSettings = {
                                                                                    ...pageNodeWithNewID.adminSettings, ...{
                                                                                        isElementAllowedToAdd: false,
                                                                                        elementAllowedToAddId: undefined
                                                                                    }
                                                                                }
                                                                                if (activeNode?.parentId === null) {
                                                                                    addNodeToActiveNode(dispatch, nodes[0], pageNodeWithNewID);
                                                                                } else if (activeNode?.parentId === '1') {
                                                                                    addNodeAfterActiveNode(dispatch, activeNode, pageNodeWithNewID, nodes);
                                                                                } else {
                                                                                    const topParent = findTopParent(activeNode.parentId, nodes[0].nodes);
                                                                                    addNodeAfterActiveNode(dispatch, topParent, pageNodeWithNewID, nodes);
                                                                                }
                                                                                dispatch(changeNewNode(pageNodeWithNewID))
                                                                                Modal.getOrCreateInstance('#container-to-add-modal').hide();
                                                                            }}
                                                                            className="text-center border-1 border-secondary border-opacity-25 p-2 border cursor-pointer">
                                                                            <img
                                                                                src={process.env.REACT_APP_IMAGE_ADDRESS + template.img}
                                                                                alt={''} style={{maxWidth: '100%'}}/>
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }


                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button-add-modal accordion-button collapsed text-capitalize fw-bold "
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="false"
                                                aria-controls="collapseOne">
                                                Containers
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body pt-0">
                                                {isMobileViewDimension || RequestPageType.MobilePage ?
                                                    <small className={'text-secondary'}>Create a container to hold the page
                                                        elements in. All elements must be in containers.
                                                        You can choose a fluid container</small>
                                                    :
                                                    <small className={'text-secondary'}>Create a container to hold the page
                                                        elements in. All elements must be in containers.
                                                        You can choose a fixed width container or fluid</small>
                                                }

                                                <div className={'d-lg-flex mt-2'}>
                                                    {!isMobileViewDimension && type !== RequestPageType.MobilePage ?
                                                        <div className={'col-lg-6 px-1 mb-2 mb-lg-0'}>
                                                            <div className={'rounded-1  blue-hover'}
                                                                onClick={() => {
                                                                    addEmptyContainer();
                                                                }}
                                                                data-bs-dismiss="modal"
                                                                style={{padding: '25px 80px'}}>
                                                                <div className={'w-100 py-5'} style={{
                                                                    border: '1px dashed #3A83F6',
                                                                    backgroundColor: "#F3F6FD"
                                                                }}></div>
                                                            </div>
                                                        </div> :
                                                        <></>
                                                    }

                                                    <div
                                                        className={`${!isMobileViewDimension && type !== RequestPageType.MobilePage ? 'col-lg-6' : 'col-lg-12'} px-1`}>
                                                        <div className={'rounded-1  blue-hover'}
                                                            onClick={() => {
                                                                addEmptyFluidContainer();
                                                            }}
                                                            data-bs-dismiss="modal"
                                                            style={{padding: '25px 4px'}}>
                                                            <div className={'w-100 py-5'} style={{
                                                                border: '1px dashed #3A83F6',
                                                                backgroundColor: "#F3F6FD"
                                                            }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {templatesData && templatesData.templateList.length > 0 && (
                                    <div className="accordion-item border-0">
                                        <h2 className="accordion-header border-0">
                                            <button
                                                className="accordion-button-add-modal accordion-button collapsed border-0 text-capitalize fw-bold"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo">
                                                Saved Blocks
                                            </button>
                                        </h2>
                                        <div id="collapseTwo"
                                            className={`accordion-collapse collapse scrollable ${isAdmin ? 'saved-blocks-scroll' : 'saved-blocks'}`}
                                            data-bs-parent="#accordionExample">
                                            <div
                                                className="accordion-body d-flex flex-wrap justify-content-between justify-content-lg-center gap-lg-4">
                                                <UserTemplates/>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    <CommonCategoryTemplates/>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="nav-elements" role="tabpanel" aria-labelledby="nav-elements-tab">
                            <ElementAddTab />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    </div>;
}

export default ContainerAddModal
