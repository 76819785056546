import React, { useEffect, useState } from 'react'
import {
    ChangeBackClassesFunction, ChangeStyleFunction,
    DeleteBackClassesByNameFunction,
    DeleteCustomStyleByNameFunction
} from "../../../../service/editModalService";
import {PageNode} from "../../../../types/PageNode";
import {AlignTypes} from "./AlignTypes";
import { Modal } from 'bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

interface InputProperty {
    value: string,
    isMobileEditMode: boolean,
    name: string,
    type: string,
    iconValue: string,
    htmlFor: string,
    editableNode: PageNode,
    changeByValueFunction: ChangeBackClassesFunction | ChangeStyleFunction,
    deleteFunction: DeleteBackClassesByNameFunction | DeleteCustomStyleByNameFunction,
    id: string
}

export const AlignmentInput: React.FC<InputProperty> = ({
                                                            value,
                                                            isMobileEditMode,
                                                            name,
                                                            type,
                                                            iconValue,
                                                            htmlFor,
                                                            editableNode,
                                                            changeByValueFunction,
                                                            deleteFunction,
                                                            id
                                                        }) => {
    const activeModal = useSelector((state: RootState) => state.modal.activeModal);
    const activeNode = useSelector((state: RootState) => state.nodeModal.value);

    const alignObject = isMobileEditMode ? editableNode.customStyleMobile || {} : editableNode.customStyle || {}
    const isMatchValue = alignObject[name] === value || editableNode.backClassesIndexed[name] === value
    let styleBorder = {background: '#ffffff', padding: '0.375rem 0.75rem', color: '#3A83F6'}

    const [selected, setSelected] = useState(isMatchValue)    

    useEffect(() => {
        if(!isMatchValue){
            setSelected(false)
        }
    }, [editableNode])

    useEffect(() => {
        const alignObject = isMobileEditMode ? activeNode.customStyleMobile || {} : activeNode.customStyle || {}

        if(activeModal === "edit-block-modal" && (alignObject[name] || (activeNode.backClassesIndexed && activeNode.backClassesIndexed[name]))){
            const isMatchValue = alignObject[name] === value || activeNode.backClassesIndexed[name] === value
            setSelected(isMatchValue)
        }else{
            setSelected(false)
        }
    }, [activeModal])

    const handleClickAlignment = () => { 
        // if (!id) {

        if(!selected){
            changeByValueFunction(name, value, isMobileEditMode)
            setSelected(true)
        }else{
            deleteFunction(name, isMobileEditMode)
            setSelected(false)
        }

        // } else {
        //     if (selected === classValue) {
        //         deleteFunction(e.target.name)
        //     } else  {
        //         changeFunction(e.target.name)
        //     }
        // }
    }

    const htmlForWithId = id ? `${htmlFor}-${id}` : htmlFor;

    return (
        <div onClick={handleClickAlignment}>
            {/*<label className="btn" htmlFor={htmlForWithId} style={selected === classValue ? styleBorder : null}>*/}
            {/*    <i className={iconValue}></i>*/}
            <label className="btn border-0" htmlFor={htmlForWithId} style={selected ? styleBorder : {
                background: '#ffffff',
                padding: '0.375rem 0.75rem',
                color: '#8B929A'
            }}>
                <i className={iconValue}></i>
            </label>
        </div>
    )
}

